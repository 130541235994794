import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StringUtils } from '@core/utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'smvd-app-input-file',
    standalone: true,
    template: `
        <div class="flex flex-row items-center mb-2">
            <label for="file-input-{{ name }}" class="cursor-pointer text-white px-4 py-2 bg-bg-btn-primary rounded-full">{{
                label | translate
            }}</label>
            <input type="file" id="file-input-{{ name }}" #fileInput hidden (change)="change($event)" />
            <span class="text-text-secondary text-ui-sm ml-2">{{ fileName }}</span>
        </div>
    `,
    styles: ['.text-white { color: white; }'],
    imports: [TranslateModule],
})
export class InputFileComponent {
    @ViewChild('fileInput') public fileInput!: ElementRef;

    @Input() name = StringUtils.getRandomString(4);
    @Input() label = 'SELECT_DOCUMENT';

    @Output() onChange = new EventEmitter<Event>();

    public fileName: string;

    public change(event: Event): void {
        this.onChange.emit(event);
        this.fileName = (event.target as HTMLInputElement).files?.[0]?.name;
    }
}
